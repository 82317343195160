// Core
import React from "react"

// Layout
import { Layout } from "../layouts"

// Components
import { Wrapper, Seo } from "../components"

const ProductPage = () => (
  <Layout>
    <Wrapper>
      <Seo title="Product" />
      <h1>Product page</h1>
      <p>Welcome to your new Outloud website.</p>
    </Wrapper>
  </Layout>
)

export default ProductPage
